import React from 'react'
import Navbar from '../components/NavBar/NavBar'
import GlobalFooter from '../components/Footer/GlobalFooter'
import StaffHeader from '../components/Staff/StaffHeader'
import StaffBody from '../components/Staff/StaffBody'

export default () => {
    return (
      <div>
        <div>
          <Navbar />
        </div>
        <div>
          <StaffHeader />
        </div>
        <div>
          <StaffBody />
        </div>
        <div>
          <GlobalFooter />
        </div>
      </div>
      )
    }