import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import './StaffHeader.css'

export default () => {
  const data = useStaticQuery(graphql`
    query staffQuery {
      file(relativePath: {eq: "Staff_images/staff.jpg"}) {
        childImageSharp {
          fluid (maxWidth: 2000, quality: 100){
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
    `)
  console.log(data);
  return (
    <div className="staff_header" id='staff_header'>
      <BackgroundImage fluid={data.file.childImageSharp.fluid} className="staff_header_background">
        <div className='staff_title'>
          <h1>牧 職 同 工</h1>
        </div>
        <div className='staff_words'>
          <h2>“ 我 為 他 已 經 丟 棄 萬 事， 看 作 糞 土， 為 要 得 著 基 督。 ”<br></br>腓 利 比 書 3 : 8</h2>
        </div>
      </BackgroundImage>
    </div>
  )
}