import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import { Col, Row } from 'antd'
import './StaffBody.css'

export default () => {
    const data = useStaticQuery(graphql`
    fragment staffImage on File {
        childImageSharp {
            fluid(maxWidth:600,maxHeight:800,quality:70,cropFocus:CENTER,fit:COVER) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
            }
        }
    }
    query StaffPortraitQuery {
        portrait1: file(relativePath: { eq: "Staff_images/何力 - 敬拜事工服侍.jpg" }) {
            ...staffImage
        }
        portrait2: file(relativePath: { eq: "Staff_images/刘志新 - 儿童主日学校长.jpg" }) {
            ...staffImage
        }
        portrait3: file(relativePath: { eq: "Staff_images/左大军 - 财务事工服侍.jpg" }) {
            ...staffImage
        }
        portrait4: file(relativePath: { eq: "Staff_images/戴林华 - 细胞小组服侍.jpg" }) {
            ...staffImage
        }
        portrait5: file(relativePath: { eq: "Staff_images/王德新 - 执事会主席.jpeg" }) {
            ...staffImage
        }
        portrait6: file(relativePath: { eq: "Staff_images/董晓锋 - 关怀事工服侍.jpg" }) {
            ...staffImage
        }
        portrait7: file(relativePath: { eq: "Staff_images/韩旭 - 教堂维护服侍.jpeg" }) {
            ...staffImage
        }
        portrait8: file(relativePath: { eq: "Staff_images/马中会 - 宣教事工服侍.jpg" }) {
            ...staffImage
        }
        portrait9: file(relativePath: { eq: "Staff_images/吕智超 - 教会信息事工服侍.jpg" }) {
            ...staffImage
        }
    }
    `);
    return (
        <div>
            <div className='desktop'>
                <div className='staff_title'>
                    <h2>中華福音教會牧職人員</h2>
                </div>
                <div className="portrait_container">
                    <Row justify="space-around">
                        <Col span={6} offset={0}>
                            <Img fluid={data.portrait1.childImageSharp.fluid} />
                            <p className='container_description'> 何力 - 敬拜事工服侍 </p>
                        </Col>
                        <Col span={6} offset={0}>
                            <Img fluid={data.portrait2.childImageSharp.fluid} />
                            <p className='container_description'> 劉志新 - 兒童主日學校長 </p>
                        </Col>
                        <Col span={6} offset={0}>
                            <Img fluid={data.portrait3.childImageSharp.fluid} />
                            <p className='container_description'> 左大軍 - 財務事工服侍 </p>
                        </Col>
                    </Row>
                    <Row justify="space-around">
                        <Col span={6} offset={0}>
                            <Img fluid={data.portrait4.childImageSharp.fluid} />
                            <p className='container_description'> 戴林華 - 細胞小組服侍 </p>
                        </Col>
                        <Col span={6} offset={0}>
                            <Img fluid={data.portrait5.childImageSharp.fluid} />
                            <p className='container_description'> 王德新 - 執事會主席 </p>
                        </Col>
                        <Col span={6} offset={0}>
                            <Img fluid={data.portrait6.childImageSharp.fluid} />
                            <p className='container_description'> 董曉鋒 - 關懷事工服侍 </p>
                        </Col>
                    </Row>
                    <Row justify="space-around">
                        <Col span={6} offset={0}>
                            <Img fluid={data.portrait7.childImageSharp.fluid} />
                            <p className='container_description'> 韓旭 - 教堂維護服侍 </p>
                        </Col>
                        <Col span={6} offset={0}>
                            <Img fluid={data.portrait8.childImageSharp.fluid} />
                            <p className='container_description'> 馬中會 - 宣教事工服侍 </p>
                        </Col>
                        <Col span={6} offset={0}>
                            <Img fluid={data.portrait9.childImageSharp.fluid} />
                            <p className='container_description'> 呂智超 - 教會信息事工服侍 </p>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='mobile'>
                <div className='staff_title'>
                    <h2>中華福音教會牧職人員</h2>
                </div>
                <div className="portrait_container">
                    <Row justify="space-around">
                        <Col span={18} offset={0}>
                            <Img fluid={data.portrait1.childImageSharp.fluid} />
                            <p className='container_description'> 何力 - 敬拜事工服侍 </p>
                        </Col>
                        <Col span={18} offset={0}>
                            <Img fluid={data.portrait2.childImageSharp.fluid} />
                            <p className='container_description'> 劉志新 - 兒童主日學校長 </p>
                        </Col>
                        <Col span={18} offset={0}>
                            <Img fluid={data.portrait3.childImageSharp.fluid} />
                            <p className='container_description'> 左大軍 - 財務事工服侍 </p>
                        </Col>
                        <Col span={18} offset={0}>
                            <Img fluid={data.portrait4.childImageSharp.fluid} />
                            <p className='container_description'> 戴林華 - 細胞小組服侍 </p>
                        </Col>
                        <Col span={18} offset={0}>
                            <Img fluid={data.portrait5.childImageSharp.fluid} />
                            <p className='container_description'> 王德新 - 執事會主席 </p>
                        </Col>
                        <Col span={18} offset={0}>
                            <Img fluid={data.portrait6.childImageSharp.fluid} />
                            <p className='container_description'> 董曉鋒 - 關懷事工服侍 </p>
                        </Col>
                        <Col span={18} offset={0}>
                            <Img fluid={data.portrait7.childImageSharp.fluid} />
                            <p className='container_description'> 韓旭 - 教堂維護服侍 </p>
                        </Col>
                        <Col span={18} offset={0}>
                            <Img fluid={data.portrait8.childImageSharp.fluid} />
                            <p className='container_description'> 馬中會 - 宣教事工服侍 </p>
                        </Col>
                        <Col span={18} offset={0}>
                            <Img fluid={data.portrait9.childImageSharp.fluid} />
                            <p className='container_description'> 呂智超 - 教會信息事工服侍 </p>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}